import React, { useEffect, useRef, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { Field } from '../new-field';
import { InputFieldParams } from './input-field';
import styled from 'styled-components';
import { useSiteConfigContext } from 'style-context';
import { getColor, ProductModuleDefinitionEmbeddedConfig } from 'site-config';
import { getFieldError, getNextComponentId, prefillBehavior, setFormFocusOrBlur } from './utils';
import { getSteppedFormInputStyle, getSteppedFormLabelColor, SteppedFormButton } from './utils/stepped-form';
import { Colors } from 'rootstrap/global-styles/colors';
import { StyledSliderRootSchemaComponent } from './styles/root-schema-component-style';
import { getValidationMessage, ValidationTypes } from 'rootstrap/components-old/root-schema-form/utils/validation';
import { formatToNumber } from './utils/number-format';
import { PseudoTooltip } from 'rootstrap/components/tooltip/pseudo-tooltip';
import { getSliderTooltipAlignment, StyledSlider } from 'rootstrap/components/slider/slider';
import { StyledDeSelectButton } from './currency-slider-field';

export const NumberSliderField = ({
  asCents = true,
  label,
  name,
  helpText,
  defaultValue,
  isDisabled,
  form,
  validators,
  prefillValue,
  prefillAction,
  disableScrollToElement,
  displayProperties,
  disableNextButton,
  increment,
  hideDivider,
  disableTitle,
  isTouched: defaultIsTouched,
  disableActiveElement,
  submitOnChange,
  hideBorder,
  hiddenComponent,
}: InputFieldParams<number> & {
  asCents?: boolean;
  increment?: number;
}) => {
  const { siteConfig } = useSiteConfigContext();
  const [isTouched, setTouched] = useState<boolean>(!!prefillValue || defaultIsTouched);
  const [fieldError, setFieldError] = useState<string | undefined>(
    getFieldError({ errors: form?.errors, isTouched, name }),
  );
  const [isRequired] = useState<boolean>(
    !!validators?.find(({ validation }) => validation.type === ValidationTypes.REQUIRED),
  );
  const defaultFormControl = useForm().control;
  const { activeElement, setActiveElement } = displayProperties;
  const numberSliderRef = useRef<any>();
  const isActive = activeElement.elementId === name || activeElement.elementId === `stepped-form-next-button-${name}`;
  const { disabledFromPrefill, hiddenFromPrefill } = prefillBehavior({
    prefillAction,
    prefillValue,
    options: undefined,
    validate: (value) =>
      getValidationMessage({
        validators: validators,
        value,
        props: undefined,
      }),
  });

  useEffect(() => {
    if (isActive && hiddenFromPrefill.display && setActiveElement && !isTouched) {
      setActiveElement({ elementId: getNextComponentId({ ...displayProperties, key: name }) });
    }
  }, [hiddenFromPrefill.display, isActive]);

  const errors = form?.errors;
  useEffect(() => {
    setFormFocusOrBlur({
      activeElement,
      scrollToId: name,
      disableScrollToElement,
      isFirstElement: displayProperties.index === 0,
      nextButtonFocus: true,
    });
  }, [JSON.stringify(errors), isTouched, name, isActive]);

  const minValidator = validators?.find(({ validation }) => validation.min);
  const maxValidator = validators?.find(({ validation }) => validation.max);

  const min = minValidator?.validation.min ? minValidator?.validation.min : undefined;
  const max = maxValidator?.validation.max ? maxValidator?.validation.max : undefined;

  return (
    <div id={name ? `${name}-form-group` : undefined} style={hiddenFromPrefill}>
      <StyledSliderRootSchemaComponent
        className='schema-component'
        isActive={isActive}
        disableActiveElement={disableActiveElement}
        isFirstElement={displayProperties.index === 0}
        hiddenComponent={hiddenComponent}
      >
        <Field
          isTouched={isTouched}
          disableTitle={disableTitle}
          isActive={isActive}
          name={name}
          hiddenComponent={hiddenComponent}
          onClick={() => setActiveElement({ elementId: name })}
          label={label}
          errors={fieldError ? [fieldError] : []}
          isRequired={isRequired}
          helpText={helpText}
          style={{
            color: getSteppedFormLabelColor({ isActive, siteConfig, color: Colors.Body }),
            fontSize: 16,
          }}
        >
          <Controller
            name={name}
            control={form?.control || defaultFormControl}
            defaultValue={defaultValue || min}
            rules={{
              required: isRequired ? 'Required' : undefined,
              validate: (value) =>
                getValidationMessage({
                  validators: validators,
                  value,
                  props: undefined,
                }),
            }}
            render={({ onChange, value }) => {
              return (
                <NumberSliderWrapper>
                  <StyledSlider
                    borderColor={getColor({ siteConfig, color: 'border' })}
                    key={`slider-${name}`}
                    handleRender={(handleProps) => {
                      const clonedProps = { ...handleProps.props, id: `slider-${name}` };

                      return (
                        <>
                          <div {...clonedProps}>
                            <PseudoTooltip
                              borderColor={getColor({ siteConfig, color: 'border' })}
                              isDisabled={!(isActive || isTouched)}
                              content={value}
                              alignment={getSliderTooltipAlignment({ value, min: min || 0, max: max || 100 })}
                            />
                          </div>
                        </>
                      );
                    }}
                    defaultValue={min}
                    sliderRailColor={
                      isActive || isTouched
                        ? getColor({ siteConfig, color: 'border' })
                        : getSteppedFormLabelColor({ isActive, siteConfig, color: Colors.Body })
                    }
                    disabled={isDisabled || disabledFromPrefill}
                    max={max}
                    ref={numberSliderRef}
                    min={min}
                    step={increment || 1}
                    primaryColor={
                      isActive || isTouched
                        ? getColor({ siteConfig, color: 'primary' })
                        : getSteppedFormLabelColor({ isActive, siteConfig, color: Colors.Body })
                    }
                    onChange={(incomingValue: any) => {
                      setActiveElement({ elementId: name });
                      setTouched(true);
                      setFieldError(
                        getValidationMessage({
                          validators: validators,
                          value: incomingValue,
                          props: undefined,
                        }),
                      );

                      onChange(incomingValue);
                      document.getElementById(`number-slider-de-select-${name}`)?.focus();
                    }}
                    value={value === '' ? value : value}
                    style={{ ...getSteppedFormInputStyle({ isActive, siteConfig }), border: 'none' }}
                  />
                  <StyledDeSelectButton id={`number-slider-de-select-${name}`} />
                  <NumberFooter
                    color={
                      isActive
                        ? Colors.Subscript
                        : getSteppedFormLabelColor({ isActive, siteConfig, color: Colors.Body })
                    }
                  >
                    <NumberMinMaxLabel siteConfig={siteConfig}>
                      Min {min && formatToNumber({ number: min })}
                    </NumberMinMaxLabel>
                    <NumberMinMaxLabel siteConfig={siteConfig}>
                      Max {max && formatToNumber({ number: max })}
                    </NumberMinMaxLabel>
                  </NumberFooter>
                </NumberSliderWrapper>
              );
            }}
          />
        </Field>
        {!hiddenComponent && (
          <SteppedFormButton
            disableActiveElement={!!disableActiveElement}
            hideBorder={hideBorder}
            submitOnChange={submitOnChange}
            setTouched={(isTouched) => setTouched(isTouched)}
            hideDivider={hideDivider}
            nextComponentId={getNextComponentId({ ...displayProperties, key: name })}
            getValue={() => form?.watch(name)}
            parentName={name}
            isDisabled={!!fieldError}
            isActive={isActive}
            setFieldError={(fieldError) => setFieldError(fieldError)}
            setActiveElement={(elementId) =>
              setActiveElement({
                elementId,
              })
            }
            validationFunction={getValidationMessage}
            validators={validators}
            validationProps={{
              prefix: undefined,
            }}
          />
        )}
      </StyledSliderRootSchemaComponent>
    </div>
  );
};

const NumberSliderWrapper = styled.div`
  padding-top: 22px;
  padding-bottom: 22px;
  border: none;
  border-radius: 8px;
  justify-content: left;
  position: relative;
`;

const NumberMinMaxLabel = styled.div<{ siteConfig: ProductModuleDefinitionEmbeddedConfig | null }>`
  color: ${({ siteConfig }) => getColor({ color: 'disabled', siteConfig })};
`;

const NumberFooter = styled.div<{ color: string }>`
  grid-template-columns: 1fr 1fr;
  width: 100%;
  display: grid;
  padding-top: 30px;

  div {
    font-size: 12px;
    color: ${Colors.Subscript};
  }

  div + div {
    width: 100%;
    display: flex;
    justify-content: end;
  }
`;
