import React, { ReactNode, useEffect, useState } from 'react';
import { Controller, useForm, UseFormMethods } from 'react-hook-form';
import { Field } from '../new-field';
import { FormStoreModelList, Validator } from 'rootstrap/components-old/root-schema-form/interfaces';
import {
  ActiveElement,
  getFieldError,
  getNextComponentId,
  getNonBreakingString,
  prefillBehavior,
  setFormFocusOrBlur,
} from './utils';
import { getSteppedFormLabelColor, onEnterKeyDown, SteppedFormButton } from './utils/stepped-form';
import { useSiteConfigContext } from 'style-context';
import { Colors } from 'rootstrap/global-styles/colors';
import { StyledRootSchemaComponent } from './styles/root-schema-component-style';
import { StyledInput } from './styled-input';
import { getValidationMessage, ValidationTypes } from 'rootstrap/components-old/root-schema-form/utils/validation';
import { StyledPrefixField, StyledPrefixContainer } from './currency-field';

export enum PrefillAction {
  Disabled = 'disabled',
  Hidden = 'hidden',
  None = 'none',
}

export interface RootSchemaFormInputFieldDisplayProperties {
  index: number | undefined;
  key: string | undefined;
  schema?: () => FormStoreModelList[] | undefined;
}

export interface StandAloneInputFieldDisplayProperties {
  nextComponentName: string;
}

export interface InputFieldDisplayProperties
  extends RootSchemaFormInputFieldDisplayProperties,
    StandAloneInputFieldDisplayProperties {
  activeElement: ActiveElement;
  setActiveElement: (params: ActiveElement) => void | undefined;
}

export type InputFieldParams<T> = {
  label: string | ReactNode;
  name: string;
  type?: 'text' | 'number' | 'textarea';
  form: UseFormMethods<any>;
  helpText?: string;
  prefix?: string;

  // validation
  validators: Validator[];
  isDisabled?: boolean;

  // value properties
  defaultValue?: T;
  placeholder: string | undefined;
  prefillValue?: any;
  prefillAction?: PrefillAction | undefined;

  // display properties
  displayProperties: InputFieldDisplayProperties;
  hiddenComponent?: boolean;
  isTouched: boolean;

  // disable attributes
  disableScrollToElement: boolean;
  disableNextButton?: boolean;
  disableTitle?: boolean;
  disableActiveElement?: boolean;
  hideDivider: boolean | undefined;
  hideBorder?: boolean;

  // Form type
  submitOnChange?: boolean;
};

export const InputField = ({
  label,
  name,
  defaultValue,
  isDisabled,
  type,
  form,
  helpText,
  placeholder,
  prefillAction,
  prefillValue,
  disableScrollToElement,
  disableNextButton,
  disableTitle,
  disableActiveElement,
  validators,
  displayProperties,
  hideDivider,
  isTouched: defaultIsTouched,
  submitOnChange,
  hideBorder,
  hiddenComponent,
  prefix,
}: InputFieldParams<string>) => {
  const { siteConfig } = useSiteConfigContext();
  const [isTouched, setTouched] = React.useState<boolean>(!!prefillValue || defaultIsTouched);
  const [fieldError, setFieldError] = React.useState<string | undefined>(
    getFieldError({ errors: form?.errors, isTouched, name }),
  );

  const [isRequired] = useState<boolean>(
    !!validators?.find(({ validation }) => validation.type === ValidationTypes.REQUIRED),
  );
  const { activeElement, setActiveElement } = displayProperties;

  const defaultFormControl = useForm().control;

  const isActive = activeElement.elementId === name;

  useEffect(() => {
    if (prefillValue) {
      setFieldError(
        getValidationMessage({
          validators: validators,
          value: form?.watch(name),
          props: undefined,
        }),
      );
    }
  }, []);

  const { disabledFromPrefill, hiddenFromPrefill } = prefillBehavior({
    prefillAction,
    prefillValue,
    options: undefined,
    validate: (value) => {
      return getValidationMessage({
        validators: validators,
        value,
        props: undefined,
      });
    },
  });

  useEffect(() => {
    if (isActive && hiddenFromPrefill.display) {
      setActiveElement({
        elementId: getNextComponentId({ ...displayProperties, key: name }),
      });
    }
  }, [hiddenFromPrefill.display, isActive]);

  const errors = form?.errors;
  useEffect(() => {
    setFormFocusOrBlur({
      activeElement,
      scrollToId: name,
      disableScrollToElement,
      isFirstElement: displayProperties.index === 0,
    });
  }, [JSON.stringify(errors), isTouched, name, isActive]);

  useEffect(() => {
    setTouched(!!prefillValue || defaultIsTouched);
  }, [defaultIsTouched]);

  return (
    <div id={name ? `${name}-form-group` : undefined} style={hiddenFromPrefill}>
      <StyledRootSchemaComponent
        className='schema-component'
        isActive={isActive}
        disableActiveElement={disableActiveElement}
        isFirstElement={displayProperties.index === 0}
        hiddenComponent={hiddenComponent}
      >
        <Field
          isTouched={isTouched}
          isActive={isActive}
          label={label}
          name={name}
          hiddenComponent={hiddenComponent}
          errors={fieldError ? [fieldError] : []}
          isRequired={isRequired}
          helpText={helpText}
          disableTitle={disableTitle}
          style={{
            color: getSteppedFormLabelColor({ isActive, siteConfig, color: Colors.Body, disableActiveElement }),
          }}
          onClick={() => setActiveElement({ elementId: name })}
        >
          <Controller
            name={name}
            control={form?.control || defaultFormControl}
            defaultValue={defaultValue}
            rules={{
              required: isRequired ? 'Required' : undefined,
              validate: (value) => {
                return getValidationMessage({
                  validators: validators,
                  value,
                  props: undefined,
                });
              },
            }}
            render={({ onChange, value }) => {
              return (
                <>
                  <StyledPrefixField>
                    {prefix && (
                      <StyledPrefixContainer siteConfig={siteConfig} isActive={isActive} isTouched={isTouched}>
                        <span>{getNonBreakingString(prefix)}</span>
                      </StyledPrefixContainer>
                    )}
                    <StyledInput
                      prefix={prefix}
                      isTouched={isTouched}
                      siteConfig={siteConfig}
                      isActive={isActive}
                      disableActiveElement={disableActiveElement}
                      onBlur={(e: React.FocusEvent<HTMLInputElement>) => e.preventDefault()}
                      onFocus={() => setActiveElement({ elementId: name })}
                      name={name}
                      disabled={isDisabled || disabledFromPrefill}
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                        const result = e.target.value;
                        setTouched(true);
                        onChange(result === '' ? undefined : result);
                        setFieldError(
                          getValidationMessage({
                            validators: validators,
                            value: result === '' ? undefined : result,
                            props: undefined,
                          }),
                        );
                      }}
                      onKeyDown={(e) =>
                        onEnterKeyDown({
                          e,
                          nextComponentId: getNextComponentId({ ...displayProperties, key: name }),
                          setFieldError: (fieldError) => setFieldError(fieldError),
                          setActiveElement: (elementId) =>
                            setActiveElement({
                              elementId,
                            }),
                          getValue: () => form?.watch(name),
                          validationFunction: getValidationMessage,
                          validators,
                          validationProps: undefined,
                          setIsTouched: (isTouched) => setTouched(isTouched),
                        })
                      }
                      value={value}
                      type={type || 'text'}
                      id={name}
                      placeholder={placeholder}
                      invalid={!!fieldError}
                      style={{
                        height: 42,
                        paddingLeft: 19,
                        paddingTop: 3,
                      }}
                    />
                  </StyledPrefixField>
                </>
              );
            }}
          />
        </Field>
        {!hiddenComponent && (
          <SteppedFormButton
            disableActiveElement={!!disableActiveElement}
            hideBorder={hideBorder}
            submitOnChange={submitOnChange}
            nextComponentId={getNextComponentId({ ...displayProperties, key: name })}
            setTouched={(isTouched) => setTouched(isTouched)}
            getValue={() => form?.watch(name)}
            parentName={name}
            isDisabled={!!fieldError}
            isActive={isActive}
            setFieldError={(fieldError) => setFieldError(fieldError)}
            setActiveElement={(elementId) =>
              setActiveElement({
                elementId,
              })
            }
            hideDivider={hideDivider}
            validationFunction={getValidationMessage}
            validators={validators}
            validationProps={undefined}
            disableNextButton={disableNextButton}
          />
        )}
      </StyledRootSchemaComponent>
    </div>
  );
};
